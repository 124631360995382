import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from "../components/layout";
import "../style/brands.less";

const BrandsIndex = ({ data }) => {
    const brands = data.allMarkdownRemark.edges

    return (
        <Layout>
            <center>
                <h1>Our Brands</h1>
            </center>
            <ul className="brands-list">
                {brands.map(({ node }, index) => (
                    <li
                        key={node.id}
                        className={`brand-item ${index % 2 === 1 && 'lightGrayBackground'}`} // Alternate classes
                    >
                        <div className={`brand-content ${index % 2 === 1 && 'right'}`}>
                            <div className="title-image-container">
                                <Link to={node.frontmatter.website}>
                                    <h2 className="brand-title">{node.frontmatter.title}</h2>
                                </Link>
                                <div className="brand-image-container">
                                    <img src={node.frontmatter.image.publicURL} alt={node.frontmatter.title} />
                                </div>
                            </div>
                            <div className="description-container">
                                <p className="brand-description">{node.frontmatter.description}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </Layout>
    )
}

export default BrandsIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/brands/" } }
      sort: { fields: [frontmatter___order, frontmatter___date], order: [ASC, DESC] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              publicURL
            }
            website
            order
          }
          html
        }
      }
    }
  }
`
